<template>
  <v-container v-if="!!payload" :id="payload.slug" class="pt-8 pb-8 pt-md-16 pb-md-16 px-0">
    <v-row align="start" justify="space-between">
      <v-col
        cols="12"
        md="6"
        class="align-self-start"
        :class="{
          'order-last': payload.box_position === BoxOrPicturePosition.Left,
        }">
        <H2Number
          :number="payload.title_has_number ? `${payload.title_number}` : ''"
          :title="`${activeTranslation?.title}`" />

        <div
          v-if="activeTranslation?.subtitle"
          class="custom-subtitle mt-6 mt-md-3 mb-6"
          v-html="sanitizeHtml(activeTranslation?.subtitle)" />

        <div
          v-if="activeTranslation?.text_content"
          class="text-cms-content"
          v-html="sanitizeHtml(activeTranslation?.text_content)" />
      </v-col>

      <v-col cols="12" md="5" class="d-flex">
        <CdeCard width="100%" :class="{ 'py-9 px-3': !mdAndUp, 'pa-9': mdAndUp }">
          <h4 v-if="activeTranslation?.list_title" class="mb-5 pb-4">
            {{ activeTranslation?.list_title }}
          </h4>
          <v-row>
            <v-col v-for="(item, index) in lisEntry" :key="index" cols="12" class="pl-0 pr-0">
              <v-row>
                <v-col
                  v-if="payload.list_type === ListBoxType.WithNumber && item.list_number"
                  cols="2"
                  sm="1"
                  md="2"
                  xl="1"
                  class="pa-0">
                  <NumberOnCircle
                    :number="item.list_number?.toString()"
                    :small="true"
                    class="mr-0" />
                </v-col>
                <v-col v-else cols="auto" class="py-1">
                  <v-icon>mdi-circle-medium</v-icon>
                </v-col>
                <v-col
                  cols="10"
                  class="pa-0 pt-1"
                  :class="{
                    'pt-2': payload.list_type === ListBoxType.WithNumber && item.list_number,
                  }">
                  <div
                    class="font-weight-thin opacity-100 text-list-entry"
                    :class="{ 'text-decoration-underline listlink': item.link_destination }"
                    @click="scrollTo(item.link_destination)"
                    v-html="sanitizeHtml(item.list_item.replace('<p>', '').replace('</p>', ''))" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </CdeCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { BoxOrPicturePosition, ListBoxType } from '@/types/enums'
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay, useGoTo } from 'vuetify'
import type { TextListCard, TextListcardTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const { locale, locales } = useI18n()
const goTo = useGoTo()

const { mdAndUp } = useDisplay()

const props = defineProps<{
  payload: TextListCard
}>()

const payload = props.payload as TextListCard

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslation: any = computed(() => {
  if (!payload.translations) {
    return {}
  }
  return payload.translations?.find(
    (t: TextListcardTranslation) => t.languages_code === currentLocaleIso.value,
  )
})

if (
  activeTranslation.value?.text_content?.includes('<table') &&
  activeTranslation.value?.text_content?.includes('border="')
) {
  activeTranslation.value.text_content = activeTranslation.value?.text_content.replace(
    /border=".*?"/g,
    '',
  )
}

const tableSpanArray = ref<HTMLSpanElement[]>([])

const lisEntry = computed(() => {
  if (!activeTranslation.value?.list_entry) {
    return []
  }
  const listEntry = activeTranslation.value?.list_entry || []
  if (payload?.title_has_number) {
    return listEntry.sort((a: any, b: any) => a.list_number - b.list_number)
  }
  return listEntry
})

const scrollTo = async (target: string) => {
  if (
    !target ||
    typeof target !== 'string' ||
    target === null ||
    target.length === 0 ||
    target.split('#')[1]?.length === 0
  ) {
    goTo(0)
  } else if (target.includes('http') || target.includes('www')) {
    await navigateTo(target, {
      open: {
        target: '_blank',
      },
    })
  } else {
    goTo(target, {
      container: 'html',
      duration: 300,
      easing: 'easeInOutCubic',
      offset: 1,
    })
  }
}

function formatTable() {
  if (activeTranslation.value?.text_content?.includes('<table') && !mdAndUp) {
    const parser = new DOMParser()

    const doc: Document = parser.parseFromString(
      activeTranslation.value?.text_content ?? '',
      'text/html',
    )

    const spanElements: NodeListOf<HTMLSpanElement> = doc.querySelectorAll('table span')

    tableSpanArray.value = Array.from(spanElements)

    const tableElement = doc.querySelector('table')
    if (tableElement) {
      tableElement.remove()
    }

    activeTranslation.value.text_content = doc.body.innerHTML
  }
}

onMounted(() => {
  formatTable()
  window.addEventListener('resize', formatTable)
})

onUnmounted(() => {
  window.removeEventListener('resize', formatTable)
})
</script>

<style scoped lang="scss">
:deep(table, tr, td, th) {
  border: none !important;
}
.listlink {
  cursor: pointer;
}
.text-list-entry {
  line-height: 1.5rem;
  padding-top: 4px;
}
</style>
